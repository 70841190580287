/************************************************************************************
LAYOUT
*************************************************************************************/

.col2-l-set {
  display: flex;

  .content-col {
    //padding-left: 108px;
    padding-left: 50px;
    order: 1;
    flex-grow: 1;
  }

  .side-col {
    flex: 0 0 288px;
    max-width: 288px;
    order: 0;
    margin-left: -12px;
  }
}

.side-nav {
  background: #fff;
  @include rem(margin, 0 0 40px 0);

  ul {
    margin: 0;

    li {
      @include rem(font-size, 15px);
      line-height: 1.3em;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid #ebebeb;

      a {
        color: #000;
        text-decoration: none;
        display: block;
        @include rem(padding, 23px 32px 23px 44px);
        position: relative;

        .icon {
          position: absolute;
          @include rem(left, 15px);
          @include rem(top, 24px);
          @include rem(width, 16px);
          @include rem(height, 16px);
        }

        &:hover {
          color: $red;
        }
      }

      &.has-subnav {
        & > a {
          &:after {
            content: '';
            width: 6px;
            height: 6px;
            display: block;
            border-left: 2px solid #000;
            border-bottom: 2px solid #000;
            @include rotate(-45deg);
            position: absolute;
            @include rem(right, 20px);
            @include rem(top, 30px);
          }
        }

        &.active {
          & > a {
            color: inherit;
          }
        }
      }

      ul {
        display: none;
      }

      &.active {
        & > a {
          background: $yellow;
        }
      }

      &.opened {
        ul {
          margin: 0;
          display: block;

          li {
            @include rem(font-size, 14px);
            margin: 0;
            padding: 0;
            border: none;
            line-height: 1.2em;
            text-transform: none;

            a {
              border: none;
              @include rem(padding, 15px 48px 15px 44px);

              &:after {
                @include rem(top, 20px);
              }
            }

            &.opened {
              ul {
                display: block;
              }
            }

            ul {
              background: #fef8e5;
              display: none;

              li {
                padding: 0;

                a {
                  color: #655f4c;
                  @include rem(padding, 15px 48px 15px 60px);

                  &:hover {
                    color: $red;
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    @include rem(left, 43px);
                    @include rem(top, 20px);
                    background: #655f4c;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.photogallery {
  .item {
    @include rem(margin-bottom, 24px);

    .img {
      position: relative;
      overflow: hidden;

      img {
        @include transition(all, 0.5);
        @include scale(1);
        width: 100%;
      }

      &:hover {
        img {
          @include scale(1.05);
        }
      }
    }
  }
}

.post {
  ul {
    li {
      position: relative;
      padding-left: 28px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        @include rem(top, 9px);
        width: 12px;
        height: 12px;
        background: url('../img/li-arr-red.svg') center center no-repeat;
        background-size: cover !important;
      }

      .label {
        @include rem(font-size, 14px);
        color: $red;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.1em;
      }
    }

    &.unstyled {
      li {
        padding: 0;

        &:before {
          content: none;
        }
      }
    }
  }

  table {
    font-size: 13px;
    //@include rem(font-size, 13px);
    line-height: 1.5em;
    width: 100%;

    th {
      border-bottom: 1px solid #d62f16;
      color: #d62f16;
      @include rem(padding, 10px 10px);
      text-align: left;
      vertical-align: middle;
      font-size: 13px;
    }

    td {
      @include rem(padding, 10px 10px);
      font-size: 13px;
    }

    tr {
      &:nth-child(even) {
        td {
          background: #fff;
        }
      }
    }

    &.phone-table {
      width: 100%;

      th,
      td {
        @include rem(padding, 15px);
      }

      td.phone {
        a {
          white-space: nowrap;
        }
      }
    }
  }

  p:last-child {
    //margin-bottom: 0;
  }

  .post-block {
    position: relative;
  }

  .block-head {
    @include rem(margin-bottom, 40px);

    h1 {
      @include rem(margin-bottom, 20px);
    }
  }

  .block-main-img {
    margin-right: -108px;
  }

  .block-excerpt {
    @include rem(font-size, 18px);
    line-height: 1.78em;
    font-weight: 600;
  }

  .meta {
    @include rem(font-size, 14px);
    line-height: 1.5em;
    font-weight: 600;
    margin: 0 0 10px 0;
    display: flex;
    flex-wrap: wrap;

    .tag-list {
      display: inline-flex;
      margin-right: 20px;

      ul {
        margin: -2px 0;
      }
    }

    .date {
      line-height: 1.5em;
    }
  }
}

.dimension {
	display: flex;
	justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  h2, h3 {
    margin: 8px 0px;
  }
  p {
    margin: 0;
    @include rem(font-size, 24px);
  }
}

.box,
.box-hl,
.box-std {
  background: #dfe0e2;
  @include rem(padding, 40px);

  .files-list {
    @include rem(margin, 40px -40px -40px -40px);

    .item-inner {
      background: #dfe0e2;
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      @include rem(padding, 30px 40px);
    }

    .item:last-child {
      .item-inner {
        @include rem(padding-bottom, 40px);
      }
    }
  }
}

.box-hl {
  background: $yellow;

  h2 {
    color: #fff;
    max-width: 640px;
  }

  .files-list {
    .item-inner {
      background: $yellow;
    }
  }
}

.box-std {
  background: #fff;
  border-bottom: 1px solid $red;
}

.spacer {
  @include rem(height, 80px);

  &.huge {
    @include rem(height, 160px);
  }

  &.big {
    @include rem(height, 100px);
  }

  &.medium {
    @include rem(height, 60px);
  }

  &.small {
    @include rem(height, 40px);
  }
}

.video {
  position: relative;
  display: block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    @include transition(all, 0.3);
  }

  .play {
    @include rem(width, 131px);
    @include rem(height, 90px);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10;
    @include translate(-50%, -50%);
    background: url('../img/play.svg') center center no-repeat;
    background-size: cover !important;
    opacity: 0.9;
    @include transition(all, 0.3);
  }

  &:hover {
    .play {
      opacity: 1;
    }

    &:after {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  img {
    width: 100%;
  }
}

.files-list {
  @include rem(margin-bottom, 40px);

  &:last-child {
    margin-bottom: 0;
  }

  .item {
    margin-top: -1px;

    &:first-child {
      margin-top: 0;
    }

    .img {
      @include rem(flex, 0 0 48px);
      @include rem(max-width, 48px);

      .icon {
        @include rem(width, 48px);
        @include rem(height, 48px);
        color: $red;
        //background: #000;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      @include rem(padding-left, 24px);
    }

    .desc {
      flex-grow: 1;
      @include rem(padding-right, 24px);
    }

    .head {
      @include rem(font-size, 17px);
      margin: 0;
      line-height: 1.2em;
    }

    .info {
      font-size: 14px;
      line-height: 1.2em;
      color: #323232;
    }

    .link {
      @include rem(width, 48px);
      @include rem(height, 48px);
      @include rem(flex, 0 0 48px);
      display: flex;
      border: 1px solid $red;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      color: $red;

      .icon {
        @include rem(width, 15px);
        @include rem(flex, 0 0 15px);
        @include rem(height, 15px);
      }
    }
  }

  .item-inner {
    display: flex;
    justify-content: flex-start;
    background: #fff;
    border: 1px solid #e2e2e2;
    @include rem(padding, 20px 24px);
    text-decoration: none;
    color: #000;
    @include transition(all, 0.5);

    &:hover {
      background: $yellow;
      border-color: $yellow;
    }
  }
}

.cat-list {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    li {
      font-size: 12px;
      margin: 2px 0;
      padding: 0 10px;
      font-weight: 700;
      text-transform: uppercase;

      a {
        color: $red;
        text-decoration: none;

        &:hover {
          color: $antracit;
        }
      }
    }
  }
}

.big-list {
  @include rem(margin-bottom, 40px);

  &:last-child {
    margin-bottom: 0;
  }

	&.flat-list {
		.item {
			.img {
        flex: 0 0 13.0rem;
        max-width: 13.0rem;
        img {
				  object-fit: cover;
				  object-position: center;
				  height: 100%;        
        }
			}
      .text {
        @include rem(padding, 20px 24px);
        align-items: center;
        color: #707070;
        @include rem(font-size, 12px);
        @include rem(line-height, 14px);
        .head {
          color: #000000;
        }
        .flat-date {
          @include rem(margin, 0 0 6px 0);
          color: #000000;
        }
      }
      padding-bottom: 14px;
		}
    .item-inner {
      padding: 0px;
      }
	}

  .item {
    margin-top: -1px;
    @include rem(font-size, 16px);
    line-height: 1.5em;

    &:first-child {
      margin-top: 0;
    }

    .img {
      @include rem(flex, 0 0 40px);
      @include rem(max-width, 40px);

      .icon {
        @include rem(width, 48px);
        @include rem(height, 48px);
        color: $red;
        //background: #000;
      }

      &.img-odbor {
        @include rem(font-size, 19px);
        line-height: 1em;
        @include rem(width, 60px);
        @include rem(height, 60px);
        @include rem(flex, 0 0 60px);
        @include rem(max-width, 60px);
        @include rem(padding, 0 10px);
        @include rem(margin-bottom, -8px);
        @include rem(padding-bottom, 5px);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: $red;

        .icon {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      @include rem(padding-left, 24px);
    }

    .desc {
      flex-grow: 1;
      @include rem(padding-right, 24px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .head {
      @include rem(font-size, 19px);
      font-weight: 700;
      @include rem(margin, 0 0 10px 0);
      line-height: 1.3em;
      flex: 0 0 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .info {
      font-size: 14px;
      line-height: 1.2em;
      color: #323232;
    }

    .link {
      @include rem(width, 48px);
      @include rem(height, 48px);
      @include rem(flex, 0 0 48px);
      display: flex;
      border: 1px solid $red;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      color: $red;

      .icon {
        @include rem(width, 15px);
        @include rem(flex, 0 0 15px);
        @include rem(height, 15px);
      }
    }
  }

  .item-inner {
    display: flex;
    justify-content: flex-start;
    background: #fff;
    color: #323232;
    border: 1px solid #e2e2e2;
    @include rem(padding, 20px 24px);
    text-decoration: none;
    color: #000;
    @include transition(all, 0.5);

    &:hover {
      background: $yellow;
      border-color: $yellow;
    }
  }
}

.medium-list {
  @include rem(margin-bottom, 40px);
  position: relative;

  h3 {
    @include rem(margin-bottom, 40px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .item {
    @include rem(font-size, 16px);
    line-height: 1.5em;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &:first-child {
      .item-inner {
        border-top: 1px solid #e2e2e2;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
    }

    .desc {
      flex-grow: 1;
      @include rem(padding-right, 24px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .head {
      @include rem(font-size, 19px);
      font-weight: 700;
      @include rem(margin, 0 0 10px 0);
      line-height: 1.3em;
      flex: 0 0 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .link {
      @include rem(width, 48px);
      @include rem(height, 48px);
      @include rem(flex, 0 0 48px);
      display: flex;
      border: 1px solid $red;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      color: $red;

      .icon {
        @include rem(width, 15px);
        @include rem(flex, 0 0 15px);
        @include rem(height, 15px);
      }
    }

    .meta {
      @include rem(font-size, 14px);
      font-weight: 600;
      @include rem(margin, 0 0 10px 0);
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      .tag-list {
        margin: 10px 0;
        display: inline-flex;
        margin-right: 20px;

        ul {
          margin: 0;
        }
      }

      .date {
        //@include rem(margin, 10px 0);
        margin: 10px 0 !important;
      }
    }

    .txt {
      flex-grow: 1;
      width: 100%;
    }
  }

  .item-inner {
    display: flex;
    justify-content: flex-start;
    color: #323232;
    border-bottom: 1px solid #e2e2e2;
    @include rem(padding, 20px 24px 20px 24px);
    text-decoration: none;
    color: #000;
    @include transition(all, 0.5);

    &:hover {
      background: $yellow;
      border-color: $yellow;
    }
  }
}

.news {
  padding: 1px;

  .item-list {
    //margin: 0 -13px;
    margin: 0;
  }

  .item {
    font-size: 14px;
    line-height: 1.7em;
    padding: 0;
    outline: 1px solid #e2e2e2;
    display: flex;

    p:last-child {
      margin-bottom: 0;
    }

    .date {
      @include rem(margin-bottom, 50px);
    }

    h3 {
      color: #000;
    }
  }

  .item-inner {
    width: 100%;
    background: #fff;
    @include rem(padding, 50px 60px);
    text-decoration: none;
    color: $antracit;

    &:hover {
      h3 {
        text-decoration: underline;
      }
    }
  }

  .foot {
    @include rem(padding-top, 30px);

    .more {
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .icon {
        margin-left: 10px;
        width: 12px;
        height: 12px;
        flex: 0 0 12px;
        position: relative;
        left: 0;
        @include transition(left, 0.3);
      }

      &:hover {
        text-decoration: underline;
        color: $antracit;

        .icon {
          left: 5px;
        }
      }
    }
  }
}

.press-news {
  overflow: hidden;

  .section-head {
    text-align: left;
    @include rem(margin-bottom, 80px);
  }

  .item-list {
    margin: 0 -30px;
  }

  .item {
    @include rem(margin-bottom, 50px);
    padding: 0 30px;

    h3 {
      a {
        color: $antracit;
        text-decoration: none;

        &:hover {
          //color: $red;
          text-decoration: underline;
        }
      }
    }

    .meta {
      @include rem(margin-bottom, 10px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .foot {
    @include rem(padding-top, 30px);

    .more {
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .icon {
        margin-left: 10px;
        width: 12px;
        height: 12px;
        flex: 0 0 12px;
        position: relative;
        left: 0;
        @include transition(left, 0.3);
      }

      &:hover {
        text-decoration: underline;
        color: $antracit;

        .icon {
          left: 5px;
        }
      }
    }
  }
}

.images {
  .item {
    @include rem(margin-bottom, 24px);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.date {
  @include rem(font-size, 14px);
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0;

  .icon {
    width: 14px;
    height: 14px;
    color: $red;
    margin-right: 10px;
    //background: $red;
  }
}

.side-col-r {
  @include rem(padding-left, 48px);
}

.instagram {
  @include rem(margin-bottom, -45px);
  max-width: 552px;

  .head {
    display: flex;
    align-items: center;
    @include rem(margin-bottom, 20px);

    h2 {
      @include rem(margin, 5px 0);
    }

    .icon {
      @include rem(width, 26px);
      @include rem(height, 26px);
      @include rem(max-width, 26px);
      @include rem(flex, 0 0 26px);
      @include rem(margin, 5px 0);
      @include rem(margin-right, 15px);
      color: #c6c7bf;
    }
  }

  .item {
    margin-bottom: 24px;

    .img {
      a {
        height: 255px;
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.facebook {
  @include rem(margin-bottom, -45px);

  .head {
    display: flex;
    align-items: center;
    @include rem(margin-bottom, 20px);

    h2 {
      @include rem(margin, 5px 0);
    }

    .icon {
      @include rem(width, 26px);
      @include rem(height, 26px);
      @include rem(max-width, 26px);
      @include rem(flex, 0 0 26px);
      @include rem(margin, 5px 0);
      @include rem(margin-right, 15px);
      color: #c6c7bf;
    }
  }

  .widget {
    width: 100%;
    height: 250px;
    background: #e3e3e3;
  }
}

.fb-widget {
  max-width: 100%;
  overflow: hidden;
}

.interests {
  display: flex;
  margin: 0 -13px;

  .main {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    display: flex;

    .item-inner {
      line-height: 1.7em;
      background: #272728;
      color: #8b8e8e;
      display: flex;
      flex-direction: column;
      padding: 0;
      outline: none;

      .img {
        justify-content: space-between;
        position: relative;
        flex-grow: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(39, 39, 40, 0.3);
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 80px;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#272728+0,272728+100&0+0,1+100 */
          background: -moz-linear-gradient(
            top,
            rgba(39, 39, 40, 0) 0%,
            rgba(39, 39, 40, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            top,
            rgba(39, 39, 40, 0) 0%,
            rgba(39, 39, 40, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to bottom,
            rgba(39, 39, 40, 0) 0%,
            rgba(39, 39, 40, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00272728', endColorstr='#272728',GradientType=0 ); /* IE6-9 */
        }
      }

      .text {
        @include rem(padding, 0 60px 50px 60px);
      }

      h3 {
        color: #c6c7bf;
        @include transition(all, 0.5);
      }

      &:hover {
        h3 {
          color: #fff;
        }
      }
    }
  }

  .side {
    flex: 0 0 66.66666%;
    max-width: 66.66666%;
  }

  .item-list {
    margin: 0;
  }

  .item {
    font-size: 14px;
    line-height: 1.7em;
    padding: 0;
    display: flex;

    p:last-child {
      margin-bottom: 0;
    }

    .date {
      @include rem(margin-bottom, 50px);
    }

    .img {
      @include rem(margin-bottom, 50px);

      .frame {
        @include rem(width, 64px);
        @include rem(height, 64px);
        background: #e3e3e3;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          @include rem(flex, 0 0 38px);
          @include rem(width, 38px);
          @include rem(height, 38px);
        }
      }
    }

    &.volna-mista {
      .img {
        .frame {
          background: #c5daee;
          color: #4174a2;
        }
      }
    }

    &.telefonni-seznam {
      .img {
        .frame {
          background: #efcac8;
          color: #811109;
        }
      }
    }

    &.cistota {
      .img {
        .frame {
          background: #d1eabc;
          color: #30730b;
        }
      }
    }

    &.dotace {
      .img {
        .frame {
          background: #f6efb6;
          color: $yellow;
        }
      }
    }
  }

  .item-inner {
    width: 100%;
    background: #fff;
    color: #000;
    @include rem(padding, 50px 60px);
    text-decoration: none;
    position: relative;
    outline: 1px solid #e2e2e2;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      height: 4px;
      background: transparent;
      @include transition(all, 0.5);
    }

    &:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 20;

      &:after {
        background: $red;
      }
    }
  }
}

.persons {
  .item {
    font-size: 14px;
    line-height: 1.5em;
    @include rem(margin-bottom, 70px);
    color: #4f4f4f;

    h2 {
      font-weight: 800;
      text-transform: uppercase;
      @include rem(margin-bottom, 10px);
    }

    .subhead {
      @include rem(font-size, 17px);
      line-height: 1.4em;
      font-weight: 600;
      color: #000;
      @include rem(margin-bottom, 20px);
    }

    .text {
      max-width: 450px;
      padding-right: 30px;
    }

    .img {
      @include rem(flex, 0 0 150px);
      @include rem(max-width, 150px);
      order: 1;
    }

    .info {
      @include rem(margin, -10px 0);

      .item {
        @include rem(margin, 10px 0);
      }

      ul {
        li {
          padding: 0;

          &:before {
            content: none;
          }
        }

        &:last-child {
          margin: 0;
        }
      }

      a {
        font-weight: 600;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .item-inner {
    display: flex;
    justify-content: space-between;
  }
}

ul.file-list {
  li {
    @include rem(padding-left, 35px);
    @include rem(margin-bottom, 10px);

    &:before {
      content: none;
    }

    /*.icon-file {
			font-size: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: $red;
			position: absolute;
			left: 0;
			top: 0;
			@include rem(width, 24px);
			@include rem(height, 32px);	
			background: url('../img/icon-file.svg');
			background-size: cover !important;
		}*/
  }
}

.icon-small-txt:before,
.icon-small-jpg:before,
.icon-small-gif:before,
.icon-small-png:before,
.icon-small-pdf:before,
.icon-small-doc:before,
.icon-small-xls:before,
.icon-small-rtf:before {
  content: '';
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $red;
  position: absolute;
  left: 0;
  top: 0;
  @include rem(width, 24px);
  @include rem(height, 32px);
  background: url('../img/icon-file.svg');
  background-size: cover !important;
}

a.icon-small-txt,
a.icon-small-jpg,
a.icon-small-gif,
a.icon-small-png,
a.icon-small-pdf,
a.icon-small-doc,
a.icon-small-xls,
a.icon-small-rtf {
  padding-left: 2rem;
  position: relative;
  padding-top: 0.5rem;
}

.icon-small-txt:before {
  content: 'txt';
}
.icon-small-jpg:before {
  content: 'jpg';
}
.icon-small-gif:before {
  content: 'gif';
}
.icon-small-png:before {
  content: 'png';
}
.icon-small-pdf:before {
  content: 'pdf';
}
.icon-small-doc:before {
  content: 'doc';
}
.icon-small-xls:before {
  content: 'xls';
}
.icon-small-rtf:before {
  content: 'rtf';
}

.search-params {
  @include rem(margin-bottom, 40px);
  line-height: 1.1em;

  p {
    @include rem(margin-bottom, 10px);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.search-hl {
  line-height: 1.2em;
  font-weight: 600;
  background: $yellow;
  color: #000;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
}

.search-results {
  .item {
    @include rem(margin-bottom, 50px);

    .item-head {
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
    }

    .head {
      display: flex;
      flex-wrap: wrap;

      h3 {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    ul {
      li {
        a {
          color: #000000;
          text-decoration: none;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }
}

.more-link {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }
}

.more-link-back {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }
}

.error-404 {
  text-align: center;

  .img {
    display: block;
    display: flex;
    justify-content: center;
    margin: 0 auto 50px auto;
  }

  .section-head {
    margin-bottom: 30px;
  }

  .foot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .search-input {
    display: block !important;
    margin: 0;
  }

  .search {
    max-width: 400px;
    flex-grow: 1;
    padding: 0 15px;
    margin: 20px 0;
  }

  .link {
    padding: 0 15px;
    margin: 20px 0;
  }
}

.calendar {
  @include rem(margin-bottom, 80px);
}

.calendar-tabs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 10px;
  border-bottom: 1px solid #cccbca;
  @include rem(margin-bottom, 30px);

  .tab-nav {
    ul {
      display: flex;
      @include rem(margin, 0 -10px);

      li {
        margin: 0;
        @include rem(padding, 0 10px);

        a {
          @include rem(font-size, 14px);
          font-weight: 500;
          border-bottom: 8px solid transparent;
          color: #000;
          text-decoration: none;
          display: inline-flex;
          align-items: center;
          @include rem(height, 80px);
          white-space: nowrap;
          @include transition(all, 0.3);

          .icon {
            width: 16px;
            height: 16px;
            @include rem(margin-right, 6px);
          }

          &:hover {
            border-color: $yellow;
          }
        }

        &.active {
          a {
            border-color: $yellow;
          }
        }
      }
    }
  }
}

.calendar-head {
  padding-top: 10px;
  @include rem(margin-bottom, 30px);
}

.calendar-switch {
  display: flex;
  align-items: center;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    @include rem(margin, 20px 0);
  }

  .prev,
  .next {
    background: #fff;
    border-radius: 50%;
    color: $red;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ececec;
    @include rem(width, 46px);
    @include rem(height, 46px);
    @include rem(flex, 0 0 46px);
    @include transition(all, 0.3);

    .icon {
      @include rem(width, 18px);
      @include rem(height, 18px);
    }

    &:hover {
      color: #fff;
      border-color: $red;
      background: $red;
    }
  }

  .head {
    display: flex;
    align-items: center;
    @include rem(font-size, 40px);
    font-weight: 700;
    line-height: 1.2em;
    padding: 0 15px;

    &.small {
      @include rem(font-size, 20px);
      line-height: 1.2em;
    }
  }
}

.calendar-filter {
  .inner {
    flex-grow: 1;
    width: 100%;
    @include rem(margin, 20px 0);
  }

  .cell {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.calendar-body {
  @include rem(font-size, 14px);
  font-weight: 500;

  ul {
    @include rem(margin-bottom, 10px);

    li {
      padding: 0;
      line-height: 1.3em;
      @include rem(margin-bottom, 10px);
    }
  }

  .num {
    @include rem(font-size, 19px);
    font-weight: 500;
    @include rem(margin-bottom, 20px);
    display: block;
  }
}

.date-cell-set {
  display: flex;
  flex-wrap: wrap;
}

.date-cell {
  width: 14.28571%;
  flex: 0 0 14.28571%;
  display: flex;
  flex-direction: column;

  .cell-head {
    @include rem(font-size, 19px);
    @include rem(padding-bottom, 20px);
    font-weight: 700;
  }

  .cell-content {
    @include rem(padding, 20px);
    border-top: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
    width: 100%;
    flex-grow: 1;
    background: #fff;
    position: relative;
  }

  &:last-child {
    .cell-content {
      border-right: none;
    }
  }

  &.weekend {
    .cell-content {
      background: #f0f0f0;
    }
  }

  &.filled {
    cursor: pointer;

    .cell-content {
      background: $yellow;

      .num {
        color: #fff;
      }
    }

    .calendar-popup {
      display: none !important;
    }
  }

  &:hover {
    .btn-add {
      display: inline-flex;
    }
  }

  .btn-add {
    display: none;
  }
}

.calendar-wrap {
  overflow-x: auto;

  .calendar-body {
    min-width: 930px;
  }
}

.calendar-week {
  .date-cell {
    .cell-content {
      @include rem(min-height, 340px);
    }
  }
}

.calendar-month {
  .date-cell {
    .cell-content {
      @include rem(min-height, 170px);
    }
  }
}

.calendar-popup {
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  @include rem(top, 65px);
  z-index: 20;
  width: 1000%;
  max-width: 480px;

  &:after {
    content: '';
    position: absolute;
    left: 12px;
    top: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #fff transparent;
  }

  .calendar-events {
    margin-bottom: 0;
  }
}

.calendar-events {
  @include rem(font-size, 16px);
  color: #656565;
  font-weight: 500;
  @include rem(margin-bottom, 80px);

  .event-item {
    @include rem(margin-bottom, 20px);

    &:last-child {
      margin-bottom: 0;
    }

    .head {
      @include rem(font-size, 17px);
      line-height: 1.3em;
      font-weight: 700;
      margin-bottom: 0;
      color: $red;
    }

    .info {
      margin-bottom: 0;
    }
  }
}

.calendar-export {
  @include rem(padding-top, 40px);

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 -10px;

    li {
      padding: 0 10px;

      a {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 10px;
          //background: $red;
        }
      }
    }
  }
}

.tag-list {
  ul {
    display: flex;

    li {
      margin: 2px 0;
      padding: 0;

      &:before {
        content: none;
      }

      a {
        @include rem(font-size, 14px);
        line-height: 1.5em;
        display: inline-flex;
        @include rem(padding, 4px 10px);
        border: 2px solid rgba(0, 0, 0, 0.1);
        text-decoration: none;
        border-radius: 5px;

        &:hover {
          border-color: $red;
          background: $red;
          color: #fff;
        }
      }
    }
  }

  &.alt {
    ul {
      li {
        a {
          background: $red;
          color: #fff;

          &:hover {
            border-color: $yellow;
            background: $yellow;
          }
        }
      }
    }
  }
}

.login-box {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  max-width: 935px;
  margin: 0 auto;

  .box-inner {
    margin: 0;
  }

  .box-img {
    padding: 0;
    background: $yellow;
    display: flex;
    align-items: flex-end;
  }

  .box-text {
    @include rem(padding, 64px);
  }
}

.logged-in {
  @include rem(padding, 15px 0);
  text-align: right;
}

.user-nav {
  display: flex;
  justify-content: center;

  ul {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      a {
        font-weight: 600;
        border: 2px solid $red;
        text-decoration: none;
        @include rem(padding, 3px 20px);
        border-radius: 5px;
        display: inline-flex;

        &:hover {
          background: $red;
          color: #fff;
        }
      }
    }
  }
}

form #contact_form_website,
#contact_form_email {
  display: none;
}

// Widths
.w-100 {
  width: 100%;
}

// Nahlaseni akce form
.form-highlight {
  color: $red;
}

.notice {
  color: #655f4c;
  font-size: 14px;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
}

.spacer {
  &.--colored {
    height: 0;
    border-bottom: 1px solid #e7e7e7;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

// Textarea rewrite
form.std textarea.height-auto {
  height: auto;
}

form.std {
  input[type='radio'] {
    margin-top: 3px;
  }
}

// Form nahlášení akcí
form.std {
  .agreement-block {
    position: relative;
    .toggle {
      position: absolute;
      top: 0.15em;
      left: 0;
    }
    .toggle-text {
      display: block;
      margin-left: 40px;
    }
  }
}

// Screen reader only class
.sr-only {
  @include sr-only;
}
