@media print {

  @page {
    //margin: 0;
  }

  body {
    font-size: 8pt;
    line-height: 1.5em;
    font-weight: 400;
    padding: 0;
    margin: 0;
    background: #fff;
  }

  h1, h1.big {
  	font-size: 20pt;
  	line-height: 1.2em;
    margin-bottom: 20pt;
  }

  h2, h2.big {
  	font-size: 18pt;
  	line-height: 1.2em;
  }

  h2.huge {
    font-size: 28pt;
    line-height: 1.2em;
  }

  h3 {
  	font-size: 16pt;
  	line-height: 1.2em;
  }

  h4 {
  	font-size: 16pt;
  	line-height: 1.2em;
  }

  img.right, img.left {
  	float: none;
  	padding: 0;
  }

  .header, .footer, .logged-in {
  	display: none;
  }

  .teaser-hp {
    padding-bottom: 30pt;

    .teaser-content {
      display: block;
    }

    .desc {
      font-size: 12pt;
      line-height: 1.5em;
    }

    .text {
      flex: 0 0 100%;
      max-width: 100%;

      .cat-list {
        margin-bottom: 10px;
      }

      h2 {
        .more {
          display: none;
        }
      }
    }

    .img {
      margin-right: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .teaser-nav {
      display: none;
    }
  }

  .teaser-slider {
    .glide__slides {
      width: 100% !important;
      transform: translate3d(0, 0, 0) !important;
    }

    .glide__slide {
      width: 100% !important;
      display: none;
      margin-left: 0 !important;
      margin-right: 0 !important;

      &.glide__slide--active {
        display: block;
      }
    }
  }

  .col2-l-set {
	  .content-col {
	  	padding-left: 0;
	  }

	  .side-col {
	  	display: none;
	  }
  }

  .post {
  	.block-main-img {
  		margin-right: 0;
  	}

  	.block-excerpt {
  		font-size: 10pt;
    	line-height: 1.3em;
  	}

  	.tag-list {
  		ul {
  			padding-left: 0;

  			li {
  				list-style-type: none;
  			}
  		}
  	}

  	ul {
  		padding-left: 15pt;

  		li {
  			padding-left: 0;
  			line-height: 1.5em;
  			list-style-type: disc;

  			&:before {
  				content: none;
  			}
  		}
  	}

  	ol {
	  	margin-bottom: 0;

	  	ol {
	  		padding-left: 10pt;
	  		margin-bottom: 0;
	  	}

	  	li {
	  		ul {
	  			padding-left: 0;
	  			margin-bottom: 0;
	  			padding-left: 10pt;

	  			li {
	  				padding-left: 0;
	  				list-style-type: disc;
	  			}
	  		}
	  	}
	  }

	  table {
	  	font-size: 8pt;
    	line-height: 1.3em;

    	td, th {
    		padding: 5pt 5pt 5pt 0;

    		&:first-child {
    			padding-left: 0;
    		}
    	}
	  }
  }

  .pager {
  	display: none;
  }

  .spacer {
  	height: 35pt;
  }

  .block {
    padding: 20pt 0;

    &.alt {
      background: none;
    }
  }

  .breadcrumbs {
    ul {
      margin: 0;
    }

    li {
      font-size: 8pt;
      padding-left: 0;

      a {
        color: #000;
      }

      &:after {
        content: none;
      }
    }
  }

  .btn {
  	//background: #000;
  	//border: none;
  	//color: #fff;
  	background: none;
  	color: $red;
  	height: auto;
  	padding: 10pt;

  	.icon {
  		color: $red;
  	}
  }

  .file-list {
  	//padding: 0 !important;

  	a {
  		padding-left: 0;

  		&:before {
  			content: none !important;
  		}
  	}
  }

  .big-list, .medium-list, .files-list {
  	//page-break-before: always;
  	
  	.item {
  		font-size: 8pt;
    	line-height: 1.5em;
      break-inside: avoid;

    	.head {
				font-size: 11pt;
    		line-height: 1.3em;
    		font-weight: 600;
    		margin-bottom: 5pt;

    		&:last-child {
    			margin-bottom: 0;
    		}
    	}

    	.link {
    		flex: 0 0 25pt;
    		width: 25pt;
    		height: 25pt;
    	}

    	.img {
    		.icon {
    			flex: 0 0 25pt;
	    		width: 25pt;
	    		height: 25pt;
    		}
    	}

    	.text {
    		padding-left: 0;
        align-items: center;
    	}
  	}

  	.item-inner {
  		padding: 5pt 10pt;
  	}
  }

  .files-list {
	  .item {
		  .info {
		  	font-size: 8pt;
		    line-height: 1.5em;
		  }
	  }
  }

  .video {
  	.play {
  		display: none;
  	}
  }

  .persons {
  	.item {
  		.info {
  			ul {
  				padding: 0;

  				li {
  					padding: 0;
  					list-style-type: none;
  				}
  			}
  		}
  	}

    .item-inner {
      break-inside: avoid;
    }
  }

  .box, .box-hl, .box-std {
  	padding: 15pt;
  	display: none;

  	.files-list {
  		margin: 0;
  	}
  }

  .side-col-r {
    padding-left: 0;
    padding-top: 40pt;
  }

  .news {
    .item {
      max-width: 100%;
      flex: 0 0 100%;

      .date {
        margin-bottom: 10pt;
      }
    }

    .item-inner {
      padding: 15pt;
    }

    .foot {
      display: none;
    }
  }

  .press-news {
    .section-head {
      margin-bottom: 20pt;
    }

    .item-list {
      margin: 0 !important;
    }

    .item {
      padding: 0;
      margin-bottom: 20pt;

      .meta {
        display: block;

        .date {
          margin-bottom: 10pt;
        }
      }
    }

    .foot {
      display: none;
    }
  }

  .news {
    .item {
      break-inside: avoid;
    }
  }

  .interests {
    margin: 0;

    .item {
      break-inside: avoid;

      .img {
        margin-bottom: 0;
        float: left;
        margin-right: 20pt;
      }
    }

    .item-inner {
      padding: 15pt;
    }

    .main {
      display: none;
    }

    .side {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .tabs {
    .tab-content {
      width: 100% !important;

      .tab {
        width: 100% !important;
        margin-right: 0 !important;
      }
    }
  }

  .interests {
    display: block;
  }

  .tag-list {
    ul {
      li {
        a {
          padding: 0 5pt;
        }
      }
    }
  }

  .photogallery {
    .item-list {
      margin-left: -15px;
      margin-right: -15px;
    }

    .item {
      float: left;
      width: 50%;
      padding: 0 15px;

      .img {
        img {
          width: auto;
        }
      }
    }
  }




  .mobile-hidden {
    display: none !important;
  }

  h2 {
    //break-before: page;
  }

  h1, h2, h3 {
    //break-after: avoid-page;
    break-inside: avoid;
  }

  img, pre {
    break-inside: avoid-page;
  }

  p {
    orphans: 4;
  }

  .container {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;

    .row {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 100%;
  }
}